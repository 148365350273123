import React from "react"

function Ant(props) {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1280.000000 777.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
            </metadata>
            <g
                transform="translate(0.000000,777.000000) scale(0.100000,-0.100000)"
                fill="#d32f2f"
                stroke="none"
            >
                <path
                    d="M4530 7747 c-94 -32 -153 -70 -235 -151 -318 -313 -562 -1125 -710
-2355 -13 -112 -26 -205 -28 -208 -3 -2 -45 7 -93 21 -92 25 -191 46 -329 68
-52 8 -175 13 -355 12 -252 0 -287 -3 -420 -27 -80 -14 -194 -40 -253 -57 -60
-18 -110 -30 -111 -28 -8 11 -41 113 -81 248 -331 1118 -676 1836 -1007 2095
-132 104 -232 140 -362 133 -144 -9 -256 -69 -407 -218 -102 -101 -144 -173
-136 -232 6 -48 21 -66 72 -89 73 -33 206 -24 473 32 l113 24 57 -30 c259
-135 559 -749 887 -1815 58 -186 84 -285 77 -288 -31 -10 -228 -135 -316 -200
-112 -84 -321 -284 -436 -417 -146 -170 -291 -412 -378 -631 -164 -412 -206
-927 -112 -1390 59 -288 164 -543 327 -789 102 -153 167 -232 311 -378 473
-480 1037 -717 1707 -717 399 0 740 77 1070 242 156 77 323 185 432 277 39 34
75 57 78 51 13 -20 45 -192 51 -272 4 -50 2 -112 -6 -155 l-12 -72 -86 -47
c-166 -89 -214 -134 -214 -200 0 -46 30 -78 88 -94 48 -14 353 -8 463 9 97 14
94 2 88 386 -4 319 -18 485 -57 720 -8 52 -7 62 9 80 27 29 115 155 165 235
l43 69 49 -37 c27 -20 79 -57 116 -82 l66 -45 11 -80 c30 -220 38 -454 21
-645 -19 -204 -20 -207 -107 -280 -123 -105 -193 -207 -193 -281 0 -135 154
-169 484 -106 171 32 159 3 159 379 0 302 -9 468 -40 711 -9 70 -14 129 -11
132 2 2 28 -5 57 -16 157 -59 360 -104 556 -123 l120 -11 14 -70 c18 -93 20
-401 3 -476 -12 -54 -17 -60 -97 -123 -165 -131 -216 -225 -168 -307 31 -53
72 -64 228 -64 133 1 319 23 372 45 41 17 47 70 46 410 -1 189 -7 370 -15 452
l-14 137 26 5 c14 3 61 10 105 16 135 19 284 56 420 105 72 25 137 49 146 51
39 13 106 -363 92 -518 l-6 -67 -84 -56 c-147 -98 -193 -150 -193 -218 0 -45
35 -82 91 -98 57 -15 211 -5 394 25 180 31 179 29 170 175 -16 264 -68 542
-156 830 -10 35 -19 68 -19 75 0 6 45 44 100 84 l100 73 67 -58 c244 -209 518
-384 770 -490 127 -54 121 -45 143 -188 12 -76 7 -127 -16 -163 -8 -12 -61
-45 -117 -73 -117 -59 -210 -127 -232 -169 -26 -50 -19 -95 18 -133 57 -56
173 -77 433 -77 143 0 152 1 172 23 37 40 57 169 57 372 0 99 -4 195 -8 214
-8 39 -20 39 157 1 54 -11 153 -29 219 -38 120 -17 121 -17 129 -47 11 -40 15
-211 5 -255 -7 -32 -15 -38 -105 -82 -163 -78 -220 -145 -188 -221 29 -70 104
-88 360 -88 173 0 191 2 209 19 39 40 46 285 14 533 l-12 96 210 6 c493 15
894 98 1295 269 309 132 613 334 858 572 341 331 516 647 592 1066 41 225 37
444 -11 698 -68 355 -265 701 -565 993 -454 440 -1026 717 -1680 814 -772 114
-1555 -8 -2154 -334 -314 -172 -625 -422 -807 -650 -43 -53 -78 -97 -78 -98
-1 -1 -67 31 -148 72 -226 114 -466 185 -725 215 -166 19 -599 8 -741 -19
-352 -67 -630 -188 -899 -392 -36 -27 -68 -49 -71 -49 -3 0 -19 23 -35 52 -91
161 -380 489 -561 635 -107 86 -264 191 -381 254 l-96 52 6 56 c3 31 13 126
21 211 60 608 176 1137 331 1507 96 229 211 409 341 533 l89 85 122 39 c153
49 266 103 315 152 31 31 37 44 37 78 0 23 -7 52 -16 64 -26 37 -112 74 -234
98 -144 30 -216 30 -300 1z"
                />
            </g>
        </svg>
    )
}

export default Ant
